// Generated by Framer (50a537b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
const MotionDivWithFX = withFX(motion.div);

const serializationHash = "framer-PJ69d"

const variantClassNames = {HUktf9zyi: "framer-v-my4n36"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, scrollSection, width, ...props}) => { return {...props, w386v2jrW: scrollSection ?? props.w386v2jrW} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;scrollSection?: React.MutableRefObject<HTMLElement>; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, w386v2jrW, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "HUktf9zyi", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><MotionDivWithFX {...restProps} {...gestureHandlers} __framer__spring={{damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}} __framer__styleTransformEffectEnabled __framer__transformTargets={[{target: {opacity: 1, rotate: -20, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, x: 0, y: 800}}, {ref: w386v2jrW, target: {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1.1, skewX: 0, skewY: 0, x: 0, y: 0}}]} __framer__transformTrigger={"onScrollTarget"} __framer__transformViewportThreshold={1} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} className={cx(serializationHash, ...sharedStyleClassNames, "framer-my4n36", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"HUktf9zyi"} ref={ref ?? ref1} style={{borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-PJ69d.framer-17yzf33, .framer-PJ69d .framer-17yzf33 { display: block; }", ".framer-PJ69d.framer-my4n36 { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 16px; height: min-content; justify-content: flex-start; min-height: 225px; overflow: visible; padding: 0px; position: relative; width: 400px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-PJ69d.framer-my4n36 { gap: 0px; } .framer-PJ69d.framer-my4n36 > * { margin: 0px; margin-bottom: calc(16px / 2); margin-top: calc(16px / 2); } .framer-PJ69d.framer-my4n36 > :first-child { margin-top: 0px; } .framer-PJ69d.framer-my4n36 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 225
 * @framerIntrinsicWidth 400
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"w386v2jrW":"scrollSection"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerQ0p1FTo5b: React.ComponentType<Props> = withCSS(Component, css, "framer-PJ69d") as typeof Component;
export default FramerQ0p1FTo5b;

FramerQ0p1FTo5b.displayName = "Testimonial";

FramerQ0p1FTo5b.defaultProps = {height: 225, width: 400};

addPropertyControls(FramerQ0p1FTo5b, {w386v2jrW: {title: "Scroll Section", type: ControlType.ScrollSectionRef}} as any)

addFonts(FramerQ0p1FTo5b, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})